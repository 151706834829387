import { Box, Text } from '@mantine/core'
import classes from './vault.module.scss'
import { useTranslation } from 'react-i18next'

export function VaultSuccess() {
  const { t } = useTranslation()
  return (
    <Box className={classes.vault}>
      <Text mt={80} size="32px" fw={800}>
        {t('vault.success')}
      </Text>
      <Text mt={30} size="20px">
        {t('vault.successMessage')}
      </Text>
    </Box>
  )
}
