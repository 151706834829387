// import { useAuth } from 'hooks/useAuth'
import axios from 'axios'
import { ReactNode } from 'react'
import { Navigate, useLocation } from 'react-router-dom'

export function RequireAuthentication({
  children,
  needsAdminRight,
}: {
  children: ReactNode | ReactNode[]
  needsAdminRight?: boolean
}) {
  const isAuthenticated = axios.defaults.headers.common.Authorization != null
  const location = useLocation()

  if (!isAuthenticated) {
    return <Navigate to={'/session/login'} state={{ from: location }} replace />
  }

  return <>{children}</>
}
