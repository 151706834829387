import { Group, Text, rem } from '@mantine/core'
import { IconUpload, IconPhoto, IconX } from '@tabler/icons-react'
import { Dropzone, DropzoneProps } from '@mantine/dropzone'
import '@mantine/dropzone/styles.css'
import { useTranslation } from 'react-i18next'

export function UploadDropzone(props: Partial<DropzoneProps>) {
  const { t } = useTranslation()
  return (
    <Dropzone
      onDrop={props.onDrop || (() => {})}
      onReject={(files) => console.log('rejected files', files)}
      maxSize={5 * 1024 * 1024 ** 2}
      {...props}
    >
      <Group justify="center" gap="xl" mih={80} style={{ pointerEvents: 'none' }}>
        <Dropzone.Accept>
          <IconUpload style={{ width: rem(52), height: rem(52), color: 'var(--mantine-color-blue-6)' }} stroke={1.5} />
        </Dropzone.Accept>
        <Dropzone.Reject>
          <IconX style={{ width: rem(52), height: rem(52), color: 'var(--mantine-color-red-6)' }} stroke={1.5} />
        </Dropzone.Reject>
        <Dropzone.Idle>
          <IconPhoto style={{ width: rem(52), height: rem(52), color: 'var(--mantine-color-dimmed)' }} stroke={1.5} />
        </Dropzone.Idle>

        <div>
          <Text size="xl" inline>
            {t('upload.drag')}
          </Text>
          <Text size="sm" c="dimmed" inline mt={7}>
            {t('upload.attach')}
          </Text>
        </div>
      </Group>
    </Dropzone>
  )
}
