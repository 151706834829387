/* eslint-disable @typescript-eslint/unbound-method */
import { ActionIcon, Anchor, AppShell, Box, Group, Text } from '@mantine/core'
import { Logo } from 'components/logo'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router'

export default function AppLayout() {
  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation()

  return (
    <AppShell
      padding="md"
      header={{
        height: 60,
      }}
    >
      <AppShell.Header>
        <Group bg="#1F0A30" style={{ height: '100%' }} px={20} justify="space-between">
          <Logo />
          <Box display="flex" style={{ alignItems: 'center' }}>
            <ActionIcon
              color="white"
              variant="subtle"
              onClick={() => changeLanguage(language === 'fr' ? 'en' : 'fr')}
              size={30}
            >
              <Text size="sm">{language === 'fr' ? 'EN' : 'FR'}</Text>
            </ActionIcon>
          </Box>
        </Group>
      </AppShell.Header>

      <AppShell.Main>
        <Box style={{ padding: '5px 15px' }}>
          <Outlet />
        </Box>

        <Box mt={80}>
          <Group mb={50} display="flex" justify="center">
            <Text c="violet" size="sm">
              © {dayjs().year()} Unicorne
            </Text>
            <Text size="sm" c="#fff" ml={5} mr={5}>
              —
            </Text>
            <Anchor
              size="sm"
              target="_blank"
              href={
                language === 'en'
                  ? 'https://www.unicorne.cloud/en/privacy-policy/'
                  : 'https://www.unicorne.cloud/politique-de-confidentialite/'
              }
            >
              {t('footer.politics')}
            </Anchor>
            <Text size="sm" c="#fff" ml={5} mr={5}>
              —
            </Text>
            <Anchor
              size="sm"
              target="_blank"
              href={
                language === 'en'
                  ? 'https://www.unicorne.cloud/en/terms-and-conditions-of-use/'
                  : 'https://www.unicorne.cloud/conditions-dutilisation/'
              }
            >
              {t('footer.conditions')}
            </Anchor>
          </Group>
        </Box>
      </AppShell.Main>
    </AppShell>
  )
}
