import './i18n'
import '@mantine/core/styles.css'
import { createRoot } from 'react-dom/client'
import { MantineProvider } from '@mantine/core'
import { Notifications } from '@mantine/notifications'
import { theme } from 'theme/theme'
import { App } from 'app'
import { setDefaultUrl } from 'config/axios'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import '@mantine/notifications/styles.css'

const container = document.getElementById('root')
const root = createRoot(container!)

setDefaultUrl(import.meta.env.VITE_API_BASE_URL)

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false } },
})

const Index = () => {
  return (
    <MantineProvider theme={theme} defaultColorScheme="light">
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
      <Notifications />
    </MantineProvider>
  )
}

root.render(<Index />)
