import { useMutation } from '@tanstack/react-query'
import axios from 'config/axios'

interface ValidateCodeEvent {
  email: string
  code: number
}

export const useValidateCode = () => {
  return useMutation<string, Error, ValidateCodeEvent>({
    mutationFn: async (values: ValidateCodeEvent) => {
      const response = await axios.post('/validate', values, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      return response.data
    },
  })
}
