/* eslint-disable @typescript-eslint/ban-ts-comment */
import axios, { AxiosRequestConfig } from 'axios'
import { errorInterceptor, requestInterceptor, responseInterceptor } from './interceptors'

let axiosRequestId: number | undefined

export function setDefaultUrl(url: string): void {
  axios.defaults.baseURL = url
}

export function manualSetRequestInterceptor(jwt: string): void {
  axios.defaults.headers.common.Authorization = `Bearer ${jwt}`
}

export function setRequestInterceptor(logoutCallback: () => Promise<void>): void {
  // callback that is called every time we make a request
  // validates the session and assign the bearer token if the session is valid
  // else throw and cancel the call made
  // todo fix ts error here
  // @ts-ignore
  axiosRequestId = axios.interceptors.request.use(async (config) => {
    let newConfig
    try {
      // newConfig = await refresh(config)
    } catch (error) {
      await logoutCallback()
      return Promise.reject(error)
    }
    return newConfig
  })
}

// async function refresh(config: AxiosRequestConfig): Promise<AxiosRequestConfig> {
//   const newConfig = { ...config }
//   const cognitoUser: CognitoUser = await Auth.currentAuthenticatedUser()
//   const currentSession: CognitoUserSession | null = await Auth.currentSession()
//   if (!currentSession) {
//     throw new Error('No session')
//   }
//   // no need to refresh if session is still valid
//   if (currentSession.isValid()) {
//     newConfig.headers = {
//       Authorization: `Bearer ${currentSession.getIdToken().getJwtToken()}`,
//     }
//     return newConfig
//   }
//   // attempt a manuel refresh even though currentSession is supposed to refresh token
//   const refreshSession = new Promise<AxiosRequestConfig>((resolve, rej) =>
//     cognitoUser.refreshSession(currentSession.getRefreshToken(), (err, session: CognitoUserSession) => {
//       // do something with the new session
//       if (err) {
//         rej(err)
//         throw new Error(err as never)
//       }

//       newConfig.headers = {
//         Authorization: `Bearer ${session.getIdToken().getJwtToken()}`,
//       }

//       return resolve(config)
//     }),
//   )
//   return refreshSession
// }

export function resetRequestInterceptor(): void {
  if (axiosRequestId !== 0 && !axiosRequestId) {
    return
  }
  axios.interceptors.request.eject(axiosRequestId)
}

axios.interceptors.request.use(requestInterceptor)
axios.interceptors.response.use(responseInterceptor, errorInterceptor)

export default axios
