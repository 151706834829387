import { ActionIcon, Table } from '@mantine/core'
import { FileWithPath } from '@mantine/dropzone'
import { IconTrash } from '@tabler/icons-react'
import { useTranslation } from 'react-i18next'
import classes from './document-table.module.scss'
import { formatBytes } from 'utils/format-bytes'
import dayjs from 'dayjs'

interface DocumentTableProps {
  files: FileWithPath[]
  removeFile: (index: number) => void
}

export function DocumentTable({ files, removeFile }: DocumentTableProps) {
  const { t } = useTranslation()
  const rows = files.map((file, index) => (
    <Table.Tr key={file.name}>
      <Table.Td>{file.name}</Table.Td>
      <Table.Td>{formatBytes(file.size)}</Table.Td>
      <Table.Td>{dayjs(file.lastModified).format('YYYY-MM-DD HH:mm')}</Table.Td>
      <Table.Td>
        <ActionIcon onClick={() => removeFile(index)} color="red">
          <IconTrash width={18} height={18} />
        </ActionIcon>
      </Table.Td>
    </Table.Tr>
  ))

  return (
    <div className={classes.wrapper}>
      <Table className={classes.table}>
        <Table.Thead>
          <Table.Tr>
            <Table.Th>{t('files.name')}</Table.Th>
            <Table.Th>{t('files.size')}</Table.Th>
            <Table.Th>{t('files.date')}</Table.Th>
            <Table.Th>{t('files.actions')}</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>{rows}</Table.Tbody>
      </Table>
    </div>
  )
}
