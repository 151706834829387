import { TextInput, Button, Text, Box, Group } from '@mantine/core'
import { useForm } from '@mantine/form'
import { useValidateCode } from 'api/query/code'
import { manualSetRequestInterceptor, setRequestInterceptor } from 'config/axios'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

interface FormValues {
  code: number
}

export const Code = () => {
  const { state } = useLocation()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { mutateAsync: validate } = useValidateCode()
  const [isLoading, setIsLoading] = useState(false)

  const form = useForm({
    validate: {
      code: (value) => (/^\d{5}$/.test(value) ? null : 'Invalid code'),
    },
  })

  async function onSubmit(values: FormValues) {
    const { code } = values
    setIsLoading(true)
    const jwt = await validate({ email: state.email, code })
    if (jwt) {
      manualSetRequestInterceptor(jwt)
    }
    navigate('/upload', { state })
    setIsLoading(false)
  }

  useEffect(() => {
    if (!state?.email) {
      navigate('/session/login')
    }
  }, [state?.email])

  return (
    <form onSubmit={form.onSubmit(onSubmit)}>
      <Text fw={800} tt="uppercase" ta="center" size="26px">
        {t('login.inputCode')}
      </Text>
      <Box mt={40}>
        <Text size="18px">{t('login.receiveCode')}</Text>
        <Box mt={30}>
          <TextInput type="number" label={t('login.code') || ''} {...form.getInputProps('code')} />
        </Box>
      </Box>
      <Group mt={30} justify="end">
        <Button type="submit" loading={isLoading} disabled={!form.isValid()} variant="outline" size="sm">
          {t('common.submit')}
        </Button>
      </Group>
    </form>
  )
}
