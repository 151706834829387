import { AppShell, Box, Text, Image, ActionIcon, Anchor, Group } from '@mantine/core'
import { Outlet } from 'react-router'
import UnicorneLogo from 'assets/logo-unicorne-white.png'
import Noise from 'assets/top-right-noise.png'
import classes from './auth.layout.module.scss'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'

export function AuthLayout() {
  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation()

  return (
    <>
      <AppShell bg="#1F0A30" h="100vh" p="2.5rem">
        <ActionIcon
          className={classes.language}
          color="white"
          variant="subtle"
          onClick={() => changeLanguage(language === 'fr' ? 'en' : 'fr')}
          size={30}
        >
          <Text size="sm">{language === 'fr' ? 'EN' : 'FR'}</Text>
        </ActionIcon>

        <Box className={classes.vault}>
          <Image
            style={{
              objectFit: 'contain',
            }}
            src={UnicorneLogo}
            alt="Unicorne Logo"
            width={200}
            height={100}
          />
          <Text mt={40} ta="center" size="34px" fw={700} fs="italic" style={{ color: 'white' }}>
            {t('vault.title')}
          </Text>
          <Box style={{ zIndex: 1 }} mt={70} className={classes.box}>
            <Outlet />
          </Box>
          <Group mt={100} display="flex" justify="center">
            <Text size="sm" c="#fff">
              © {dayjs().year()} Unicorne
            </Text>
            <Text size="sm" c="#fff" ml={5} mr={5}>
              —
            </Text>
            <Anchor
              size="sm"
              c="#fff"
              target="_blank"
              href={
                language === 'en'
                  ? 'https://www.unicorne.cloud/en/privacy-policy/'
                  : 'https://www.unicorne.cloud/politique-de-confidentialite/'
              }
            >
              {t('footer.politics')}
            </Anchor>
            <Text size="sm" c="#fff" ml={5} mr={5}>
              —
            </Text>
            <Anchor
              size="sm"
              c="#fff"
              target="_blank"
              href={
                language === 'en'
                  ? 'https://www.unicorne.cloud/en/terms-and-conditions-of-use/'
                  : 'https://www.unicorne.cloud/conditions-dutilisation/'
              }
            >
              {t('footer.conditions')}
            </Anchor>
          </Group>
        </Box>
      </AppShell>

      <Image
        src={Noise}
        alt="Unicorne Noise"
        mah={550}
        maw={550}
        style={{
          position: 'absolute',
          zIndex: 0,
          objectFit: 'fill',
          right: 0,
          top: 0,
        }}
      />
    </>
  )
}
