import { useMutation } from '@tanstack/react-query'
import axios from 'config/axios'

interface LoginEvent {
  email: string
}

export const useLogin = () => {
  return useMutation<void, Error, LoginEvent>({
    mutationFn: async (values: LoginEvent) => {
      const response = await axios.post('/login', values, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      return response.data
    },
  })
}
