// import { ProvideAuth } from 'hooks/useAuth'
import { Navigate, Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom'
import AppLayout from 'layout/app.layout'
import { AuthLayout } from 'layout/auth.layout'
import { IndexLayout } from 'layout/index.layout'
import { Vault } from 'pages/vault/vault'
import { RequireAuthentication } from 'pages/require-authentication'
import { Login } from 'pages/session/login'
import { Code } from 'pages/session/code'
import { VaultSuccess } from 'pages/vault/vault.success'

export function App() {
  return <RouterProvider router={router} />
}

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<IndexLayout />}>
      <Route path="session" element={<AuthLayout />}>
        <Route path="login" element={<Login />} />
        <Route path="code" element={<Code />} />
        <Route index element={<Navigate to="/session/login" />} />
      </Route>
      <Route
        element={
          <RequireAuthentication>
            <AppLayout />
          </RequireAuthentication>
        }
      >
        <Route path="upload" element={<Vault />} />
        <Route path="success" element={<VaultSuccess />} />
        <Route path="*" element={<Navigate to="/upload" />} />O
      </Route>
      <Route path="*" element={<Navigate to="/session/login" />} />O
    </Route>,
  ),
)
