import { Image, Text } from '@mantine/core'
import UnicorneLogo from 'assets/logo.png'
import { useNavigate } from 'react-router-dom'

export function Logo() {
  const navigate = useNavigate()
  return (
    <Image
      style={{ cursor: 'pointer' }}
      onClick={() => navigate('/session/login')}
      src={UnicorneLogo}
      alt="Logo"
      width={35}
      height={35}
    />
  )
}
