import { TextInput, Button, Text, Box, Anchor, PasswordInput, Group } from '@mantine/core'
import { UseFormReturnType, useForm } from '@mantine/form'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
// import { useAuth } from 'hooks/useAuth'
import { useNavigate } from 'react-router-dom'
import { NS } from 'i18n'
import { useLogin } from 'api/query/login'

interface FormValues {
  email: string
}

export const Login = () => {
  const { mutateAsync: login } = useLogin()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)

  const form = useForm({
    initialValues: {
      email: '',
    },
    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
    },
  })

  async function onSubmit(values: FormValues) {
    const { email } = values
    setIsLoading(true)
    await login({ email })
    navigate('/session/code', { state: { email } })
    setIsLoading(false)
  }

  return (
    <form onSubmit={form.onSubmit(onSubmit)}>
      <Text fw={800} tt="uppercase" ta="center" size="26px">
        {t('login.continue')}
      </Text>
      <Box mt={30}>
        <Text size="16px">{t('login.input')}</Text>
        <Text mt={5} size="16px">
          {t('login.receive')}
        </Text>
        <Box mt={20}>
          <TextInput label={t('login.email') || ''} {...form.getInputProps('email')} />
        </Box>
      </Box>
      <Group mt={30} justify="end">
        <Button type="submit" disabled={!form.isValid()} loading={isLoading} variant="outline" size="sm">
          {t('common.submit')}
        </Button>
      </Group>
    </form>
  )
}
