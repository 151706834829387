import { Box, Button, Group, Progress, Table, Text, TextInput, Textarea } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { useForm } from '@mantine/form'
import { useCallback, useEffect, useState } from 'react'
import { showNotification } from '@mantine/notifications'
import { AxiosProgressEvent } from 'axios'
import { useGetSignedUrl } from 'api/query/upload'
import { fileAxios } from 'api/file/file-axios'
import { UploadDropzone } from 'components/upload/upload-dropzone'
import { FileWithPath } from '@mantine/dropzone'
import { DocumentTable } from 'components/table/document-table'
import { useLocation, useNavigate } from 'react-router-dom'
import classes from './vault.module.scss'

interface FormValues {
  firstName: string
  lastName: string
  email: string
  message: string
  files: FileWithPath[]
}

export function Vault() {
  const { t } = useTranslation()
  const { state } = useLocation()
  const navigate = useNavigate()
  const form = useForm<FormValues>({
    initialValues: {
      firstName: '',
      email: state.email || '',
      lastName: '',
      message: '',
      files: [],
    },
    validate: {
      firstName: (value) => (value.length > 0 ? null : 'First name is required'),
      lastName: (value) => (value.length > 0 ? null : 'Last name is required'),
      message: (value) => (value.length > 0 ? null : 'Message is required'),
      files: (value) => (value.length > 0 ? null : 'Files are required'),
    },
  })
  const { mutateAsync: getSignedUrl } = useGetSignedUrl()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [uploadPercentageCompleted, setUploadPercentageCompleted] = useState<number | null>(null)

  const [totalSize, setTotalSize] = useState(0)
  const [progress, setProgress] = useState<number[]>([]) // Progress for each file

  const onUploadProgress = (index: number) => (progressEvent: AxiosProgressEvent) => {
    setProgress((oldProgress) => {
      const newProgress = [...oldProgress]
      newProgress[index] = progressEvent.loaded
      return newProgress
    })
  }

  async function onSubmit(values: any) {
    setIsSubmitting(true)
    const { files, ...rest } = values

    setProgress(new Array(files.length).fill(0)) // Initialize progress for each file

    try {
      await Promise.all(
        files.map(async (file: File, index: number) => {
          const { preSignedUrl } = await getSignedUrl({ ...rest, name: file.name })
          await fileAxios.put(preSignedUrl, file, {
            onUploadProgress: onUploadProgress(index),
            headers: { 'Content-Type': file.type },
          })
        }),
      )

      navigate('/success', { state: undefined })
    } catch (error) {
      showNotification({
        title: 'Error',
        message: 'An error occurred while uploading your files',
        color: 'red',
      })
    }

    setIsSubmitting(false)
  }

  useEffect(() => {
    const totalSize = form.values.files.reduce((total, file) => total + file.size, 0)
    setTotalSize(totalSize)
  }, [form.values.files])

  // Calculate total progress
  useEffect(() => {
    const totalUploadedSize = progress.reduce((total, current) => total + current, 0)
    const percentCompleted = Math.round((totalUploadedSize * 100) / totalSize)

    if (!percentCompleted || isNaN(percentCompleted) || totalSize === 0) {
      return
    }

    setUploadPercentageCompleted(percentCompleted)
  }, [progress, totalSize])

  return (
    <Box className={classes.vault}>
      <Group justify="center">
        <Text size="30px" fw={700}>
          {t('vault.title')}
        </Text>
      </Group>
      {/* <Group mt={10} justify="center">
        <Text className={classes.description} size="md" fw={600}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
          magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
          commodo.
        </Text>
      </Group> */}

      <form style={{ width: '100%' }} onSubmit={form.onSubmit(onSubmit)}>
        <Box mt={10} display="flex" className={classes.column}>
          <TextInput
            w="100%"
            size="sm"
            withAsterisk
            label={t('user.firstName')}
            placeholder={t('user.firstName')}
            {...form.getInputProps('firstName')}
          />
          <TextInput
            w="100%"
            size="sm"
            withAsterisk
            label={t('user.lastName')}
            placeholder={t('user.lastName')}
            {...form.getInputProps('lastName')}
          />
        </Box>
        <TextInput
          size="sm"
          disabled
          type="email"
          label={t('user.email')}
          placeholder={t('user.email')}
          mt={10}
          {...form.getInputProps('email')}
        />
        <Textarea
          size="sm"
          withAsterisk
          label={t('user.message')}
          placeholder={t('user.message')}
          mt={10}
          rows={2}
          {...form.getInputProps('message')}
        />

        <UploadDropzone
          mt={15}
          loading={isSubmitting}
          onDrop={(files) => {
            files.forEach((file) => {
              form.insertListItem('files', file)
            })
          }}
        />

        {form.values.files.length > 0 && (
          <Box mt={15}>
            <DocumentTable files={form.values.files} removeFile={(index) => form.removeListItem('files', index)} />
          </Box>
        )}

        {uploadPercentageCompleted != null && (
          <Box mt={8}>
            <Progress value={uploadPercentageCompleted} />
          </Box>
        )}

        <Group mt={15} justify="end">
          <Button variant="outline" size="sm" type="submit" loading={isSubmitting}>
            Submit
          </Button>
        </Group>
      </form>
    </Box>
  )
}
