import { Outlet } from 'react-router-dom'

export const IndexLayout = () => {
  // const { appIsHydrated } = useAuth()

  // if (!appIsHydrated) {
  //   return <Loader />
  // }

  return <Outlet />
}
