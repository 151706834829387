import { useMutation } from '@tanstack/react-query'
import axios from 'config/axios'

interface FileEvent {
  firstName: string
  lastName: string
  message: string
  fileName: File
}

export const useGetSignedUrl = () => {
  return useMutation<{ preSignedUrl: string; expires: number }, Error, FileEvent>({
    mutationFn: async (values: FileEvent) => {
      const response = await axios.post('/files', values, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      return response.data
    },
  })
}
